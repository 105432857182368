
/* SalesSection.module.css */
.container {
  padding: 3rem 0;
  background-color: #ffffff;
  transform: skewX(-6deg);

}

/* Image Container */
.imageContainer {
  width: 100%;
  max-width: 50%;
  height: auto;
  overflow: hidden;
  margin-right: 2rem;
  position: relative;
  transform: skewX(-6deg);

}

.skewedImage {
  width: 100%;
  height: auto;
  transform: skewX(-0deg); /* Image zoom effect on hover */
  transition: transform 1.5s ease-in-out;
}


/* Text Content */
.textContent {
  padding: 2rem;
  color: #333;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.subtitle {
  font-style: italic;
  color: #777;
  margin-bottom: 1.5rem;
}

.description {
  font-size: 1rem;
  color: #555;
  margin-bottom: 2rem;
}

.markerList {
  list-style: none;
  padding: 0;
  margin-bottom: 2rem;
}

.markerList li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
  font-size: 1rem;
  color: #555;
}

.markerList li span {
  color: #e53e3e; /* Tailwind red-800 */
  margin-right: 0.5rem;
  font-size: 1.25rem;
}

/* Button Container */
.buttonContainer {
  text-align: center;
}

.actionButton {
  background-color: #991b1b;
  color: #ffffff;
  padding: 0.75rem 2rem;
  border-radius: 50px;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease-in-out;
}

.actionButton:hover {
  background-color: #c53030; /* Darker red on hover */
}

/* mobile */
@media (max-width: 768px) {
  .container {
    transform: skewX(0deg);
  }

  .imageContainer {
    max-width: 100%;
    margin-right: 0;
  }

  .textContent {
    padding: 1rem;
  }

  .title {
    font-size: 1.5rem;
  }

  .subtitle {
    font-size: 1rem;
  }

  .description {
    font-size: 0.875rem;
  }

  .markerList li {
    font-size: 0.875rem;
  }

  .actionButton {
    padding: 0.5rem 1.5rem;
  }
}