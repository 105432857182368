/* General Styles */
body {
    font-family: Arial, sans-serif;
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0;
}

.col-sm-3 {
    flex: 0 0 23%; /* Each column takes up about 23% of the width */
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    justify-content: center;
}

.experience-item {
    background-color: #f4f4f4; /* Light gray background */
    width: 150px;
    height: 150px;
    border-radius: 50%; /* Make it circular */
    padding: 20px;
    text-align: center;
    border: 5px solid transparent; /* Initial border for after effect */
    transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.experience-item-title {
    font-size: 24px; /* Adjusted size for circular shape */
    font-weight: bold;
    color: #333; /* Darker text color */
    margin: 0;
}

.experience-item-text {
    font-size: 14px;
    color: #777; /* Lighter text color */
    margin-top: 10px;
    text-align: center;
}

/* Active state styling */
.experience-item.active {
    background-color: #fff; /* Slightly darker background for active item */
    border-color: red; /* Border color for active item */
}

/* Tooltip styling */
.experience-item-active {
    position: absolute;
    top: -50px; /* Position above the circle */
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.experience-item-active img {
    width: 20px;
    height: 20px;
    margin-bottom: 5px; /* Adjust margin for spacing */
}

.tool-tip {
    background-color: #333;
    color: #fff;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 4px;
    white-space: nowrap;
    position: relative;
    top: 15px; /* Adjust position for spacing */
    left: 100%;
}

/* Tooltip arrow */
.tool-tip::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
}

/* Hover Effects */
.experience-item:hover {
    transform: translateY(-5px) scale(1.05);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    border-color: red; /* Border color on hover */
}

/* Animation */
.animation {
    opacity: 0;
    animation: fadeIn 1s forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

[data-animation="fadeIn"] {
    animation-name: fadeIn;
}

[data-animation-delay="0s"] {
    animation-delay: 0s;
}

/* Responsive Design */
@media (max-width: 768px) {
    .col-sm-3 {
        flex: 0 0 48%; /* Two columns per row on tablets */
        margin-bottom: 20px;
    }
}

@media (max-width: 576px) {
    .col-sm-3 {
        flex: 0 0 100%; /* One column per row on mobile devices */
        margin-bottom: 20px;
    }

    .experience-item {
        width: 120px;
        height: 120px;
    }
}
